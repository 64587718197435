.about {
    line-height: 1.75rem;
    .core {
        max-width: 1200px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0 auto;
        .cursive {
            font-family: Barcelony;
            font-size: 2.5rem;
        }
        a {
            color: $darkgrey;
        }
        .col1 {
            margin: 2rem 4rem;
            max-width: 400px;

            .description {
                text-align: justify;
                margin: 1rem 0;
                line-height: 1.75rem;
            }
            .contact {
                float: right;
                .cursive {
                    font-size: 2rem;
                    color: $red;
                    margin-top: 1.5rem;
                    position: relative;
                    left: -2.5rem;
                }
                .details {
                    font-weight: bold;
                    p {
                        line-height: 1rem;
                    }
                }
            }
        }
        .col2 {
            margin: 0 1.75rem;
            .p1 {
                padding: 2rem 4rem;
                h2 {
                    margin-bottom: 1.5rem;
                }
                p {
                    text-align: justify;
                    line-height: 1.75rem;
                }
                margin-bottom: 3rem;
            }
            .p2 {
                background-color: $beige;
                padding: 0 4rem 2rem;
                h2 {
                    font-weight: normal;
                    line-height: 2rem;
                    position: relative;
                    left: -4rem;
                    top: -1rem;
                }
                p {
                    text-align: justify;
                    line-height: 1.75rem;
                    margin-bottom: 1rem;
                }
            }
            .p3 {
                padding: 2rem 4rem;
                margin-top: 2rem;
                h2 {
                    margin-bottom: 1.5rem;
                }
                p {
                    text-align: justify;
                }
            }
            .quote {
                margin: 2rem 0 4rem;
                .Rumi {
                    font-size: 1.875rem;
                    text-align: right;
                    margin-top: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .about {
        .core {
            max-width: 1200px;
            display: flex;
            flex-flow: column-reverse nowrap;
            justify-content: center;
            margin: 0 auto;
            .col1 {
                max-width: 600px;
                margin: 2rem auto;
                img {
                    width: 600px;
                }
                .contact {
                    margin-bottom: 2rem;
                }
            }
            .col2 {
                .quote {
                    max-width: 600px;
                    margin: 2rem auto;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 560px) {
    .about {
        .core {
            .cursive {
                font-size: 1.75rem;
            }
            .col1 {
                margin: 0;
                img {
                    width: 100vw;
                }
                .description {
                    margin: 2rem;
                }
                .contact {
                    float: left;
                    margin: 1rem 2rem 3rem;
                    .cursive {
                        font-size: 2rem;
                        margin-top: 0rem;
                        position: relative;
                        left: 0;
                    }
                    .details {
                        text-align: right;
                        position: relative;
                        right: -10%;
                        margin-top: 1rem;
                        font-weight: bold;
                        p {
                            line-height: 1.5rem;
                        }
                    }
                }
            }
            .col2 {
                margin: 2rem;
                .p1 {
                    padding: 0;
                    margin-bottom: 4rem;
                }
                .p2 {
                    padding: 0 1.5rem 1rem;
                    h2 {
                        left: -3vw;
                    }
                    p {
                        margin-bottom: 1rem;
                    }
                }
                .p3 {
                    padding: 0;
                    margin: 0;
                    margin-top: 4rem;
                }
                .quote {
                    .Rumi {
                        margin: 0;
                    }
                }
            }
        }
    }
}