*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

html {
    box-sizing: inherit;
}

q:before, q:after {
    content: "";
}