.error404 {
    width: 100%;
    position: absolute;
    width: 100vw;
    height: 100vh;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    p {
        font-size: 1.25rem;
        padding: 2rem;
        margin: 20vh auto;
        text-align: center;
    }
}