header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 32px 120px;

    h1 {
        text-align: right;
        z-index: 1;
    }

    ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    h1 {
        a {
            color: $darkgrey;
        }
    }

    li {
        a {
            color: $darkgrey;
            margin-left: 2rem;
        }
    }

    .burger_menu {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    header {
        padding: 32px 64px;
    }
}

@media screen and (max-width: 560px) {
    header {
        padding: 32px;
        nav {
            display: none;
        }
        
        .show {
            display: block;
            position: absolute;
            inset: 0;
            background-color: $beige;
            padding-top: 16vh;
            padding-left: 1.75rem;
            ul {
                flex-flow: column nowrap;
                li {
                    margin-top: 1rem;
                }
            }
        }

        .burger_menu {
            display: block;
            background-color: $white;
            width: 56px;
            height: 56px;
            border: 0;
            cursor: pointer;
        }

        .burger_menu::before {
            content: url("../assets/icons/burger.svg");
            display: inline-block;
            position: relative;
            top: -12px;
            right: -10px;
            transform: scale(1.4);
        }
    }
}