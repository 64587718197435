body {
    box-sizing: border-box;
    font-family: NunitoSans, sans-serif;
    line-height: 1.75rem;
    font-size: 1rem;
    color: $darkgrey;
}

h1,
h2 {
    font-size: 1.5rem;
}

li > a {
    font-size: 1.25rem;
}

@keyframes image-fade-in-bottom {
    from {
        opacity: 0;
        top: 200px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}

@keyframes image-fade-in-left {
    from {
        opacity: 0;
        right: 200px;
    }
    to {
        opacity: 1;
        right: 0px;
    }
}

@keyframes image-fade-in-right {
    from {
        opacity: 0;
        left: 200px;
    }
    to {
        opacity: 1;
        left: 0px;
    }
}