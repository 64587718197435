footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 4rem;
    background-color: $red;
    color: $white;

    .contact {
        text-align: end;
    }

    a {
        color: $white;
    }

    h2 {
        font-weight: bold;
    }

    li {
        min-width: 150px;
    }

    .description {
        max-width: 768px;
        text-align: justify;
        margin: 0 120px;
    }
}

@media screen and (max-width: 1400px) {
    footer {
        display: block;
        padding: 2rem;

        .description {
            margin: 56px auto;
        }
    }
}