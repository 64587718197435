.gestalt {
    section:nth-child(1) {
        max-width: 1400px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        margin: 4rem auto;
        .left {
            max-width: 600px;
            margin: 2rem;
            p {
                margin-top: 1.5rem;
                text-align: justify;
            }
        }

        .right {
            max-width: 600px;
            margin: 2rem;
            p:not(:first-child) {
                margin-top: 1.5rem;
            }

            .cursive {
                font-family: Barcelony;
                font-size: 2rem;
                margin-bottom: 1.5rem;
                position: relative;
                left: -2rem;
                line-height: 2.5rem;
            }

            img {
                position: relative;
                animation: image-fade-in-right 1s ease .3s 1 normal backwards;
            }
        }
    }
    section:nth-child(2) {
        background-color: $beige;
        div {
            max-width: 1170px;
            margin: 0 auto;
            padding: 4rem;

            p {
                margin-top: 2rem;
                text-align: justify;
            }
        }
    }
    section:nth-child(3) {
        max-width: 1300px;
        display: flex;
        flex-flow: row nowrap;
        margin: 6rem auto;

        .framework {
            width: 44%;
            margin: 0 4rem;

            p {
                margin-top: 2rem;
                text-align: justify;
            }
        }

        .cta {
            margin: 0 auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
        
            .catchphrase {
                font-family: Barcelony;
                font-size: 2rem;
                text-align: center;
                margin-bottom: 2rem;
            }
    
            .left {
                position: relative;
                left: -50px;
            }
    
            .right {
                position: relative;
                left: 50px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .gestalt {
        section:nth-child(1) {
            .right {
                img {
                    width: 50vw;
                    position: static;
                    animation: none;
                }
                .cursive {
                    left: 0;
                }
            }
        } 
    }
}

@media screen and (max-width: 800px) {
    .gestalt {
        section:nth-child(1) {
            display: block;
            .left, .right {
                max-width: 100%;
                margin: 4rem;

                img {
                    width: 100vw;
                    position: relative;
                    left: -4rem;
                }
            }
        }

        section:nth-child(3) {
            display: block;
            margin: 2rem;

            .framework {
                width: auto;
                margin: 2rem;
            }

            .cta {
                margin: 4rem 2rem;
            }
        }   
    }
}

@media screen and (max-width: 560px) {
    .gestalt {
        section:nth-child(1) {
            .left, .right {
                margin: 2rem;
                .cursive {
                    font-size: 1.5rem;
                }

                img {
                    position: relative;
                    left: -2rem;
                }
            }
        }
        section:nth-child(2) {
            background-color: $beige;
            div {
                padding: 2rem;
            }
        }
        section:nth-child(3) {
            display: block;
            margin: 1rem;

            .framework {
                width: auto;
                margin: 2rem 1rem;
            }
            
            .cta {
                margin: 4rem 2rem;
                
                .catchphrase {
                    font-size: 1.75rem;
                }
            }
        }   
    }
}