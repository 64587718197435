.button {  
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 356px;
    min-width: 232px;
    height: 58px;
    margin: 0 auto;
    border: 2px solid $red;
    border-radius: 4px;
    cursor: pointer;
    font-family: NunitoSans;
    font-size: 20px;
    font-weight: bold;

    &__main {
        color: $white;
        background-color: $red;
    }

    &__secondary {
        color: $red;
        background-color: $beige;
    }
}

.active {
    color: $green;
}