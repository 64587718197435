.home {
    .core {
        display: flex;
        flex-flow: row nowrap;
        max-width: 1600px;
        margin: 8rem auto;
    
        img {
            max-width: 720px;
            aspect-ratio: 1;
            position: relative;
            animation: image-fade-in-bottom 1s ease .3s 1 normal backwards;
        }

        div {
            max-width: 500px;
            margin: 0 auto;

            section {
                margin-bottom: 3rem;
                h2 {
                    margin-bottom: 2rem;
                }
                p {
                    text-align: justify;
                }
            }
        
            .catchphrase {
                font-family: Barcelony;
                font-size: 2rem;
                text-align: center;
                margin-bottom: 2.5rem;
            }
    
            .left {
                position: relative;
                left: -50px;
            }
    
            .right {
                position: relative;
                left: 50px;
            }
        }
    
    }
    
    .carousel {
        font-weight: bold;
        font-size: 1.5rem;
        background-color: $beige;
        padding: 3rem 0;

        p {
            text-align: center;
            margin: 1.5rem 0;
        }

        p::before {
            content: url("../assets/icons/quotes.svg");
            display: inline-block;
            position: relative;
            right: 8px;
            bottom: 8px;
        }

        p::after {
            content: url("../assets/icons/quotes.svg");
            display: inline-block;
            position: relative;
            left: 16px;
            top: 4px;
            transform: rotate(180deg);
        }

        #quotes {
            fill: $red;
        }

    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    button {
        border: none;
        width: .75rem;
        height: .75rem;
        color: transparent;
        background-color: transparent;
    }
    li {
        width: .75rem;
        height: .75rem;
        border-radius: 100%;
        background-color: $lightred;
        margin: 0 .25rem;
        cursor: pointer;
        &.slick-active {
            background-color: $red;
        }
    }
}

@media screen and (max-width: 1400px) {
    .home {
        .core {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 auto;

            img {
                max-width: 600px;
                margin: 2rem auto;
                position: static;
                animation: none;
            }

            div {
                max-width: 600px;
                margin: 2rem auto 4rem;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    
}

@media screen and (max-width: 560px) {
    .home {
        .core {
            img {
                max-width: 100vw;
                margin: 1rem auto;
            }

            div {
                margin: 1.5rem;
        
                .catchphrase {
                    font-size: 1.75rem;
                }
            }
        }
    }
}