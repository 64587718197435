@font-face {
    font-family: "Barcelony";
    src: url("../assets/fonts/Barcelony/Barcelony.ttf") format("truetype");
}

@font-face {
    font-family: "NunitoSans";
    src: url("../assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "NunitoSans";
    src: url("../assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}