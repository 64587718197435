@font-face {
  font-family: "Barcelony";
  src: url("Barcelony.2f17281c.ttf") format("truetype");
}
@font-face {
  font-family: "NunitoSans";
  src: url("NunitoSans-Regular.e6a960a7.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NunitoSans";
  src: url("NunitoSans-Bold.17d23c5b.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
html {
  box-sizing: inherit;
}
q:before, q:after {
  content: "";
}
body {
  box-sizing: border-box;
  font-family: NunitoSans, sans-serif;
  line-height: 1.75rem;
  font-size: 1rem;
  color: #313131;
}
h1,
h2 {
  font-size: 1.5rem;
}
li > a {
  font-size: 1.25rem;
}
@keyframes image-fade-in-bottom {
  from {
    opacity: 0;
    top: 200px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}
@keyframes image-fade-in-left {
  from {
    opacity: 0;
    right: 200px;
  }
  to {
    opacity: 1;
    right: 0px;
  }
}
@keyframes image-fade-in-right {
  from {
    opacity: 0;
    left: 200px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 356px;
  min-width: 232px;
  height: 58px;
  margin: 0 auto;
  border: 2px solid #D55860;
  border-radius: 4px;
  cursor: pointer;
  font-family: NunitoSans;
  font-size: 20px;
  font-weight: bold;
}
.button__main {
  color: #FFFFFF;
  background-color: #D55860;
}
.button__secondary {
  color: #D55860;
  background-color: #FBF7F1;
}
.active {
  color: #98C27F;
}
header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 32px 120px;
}
header h1 {
  text-align: right;
  z-index: 1;
}
header ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
header h1 a {
  color: #313131;
}
header li a {
  color: #313131;
  margin-left: 2rem;
}
header .burger_menu {
  display: none;
}
@media screen and (max-width: 1400px) {
  header {
    padding: 32px 64px;
  }
}
@media screen and (max-width: 560px) {
  header {
    padding: 32px;
  }
  header nav {
    display: none;
  }
  header .show {
    display: block;
    position: absolute;
    inset: 0;
    background-color: #FBF7F1;
    padding-top: 16vh;
    padding-left: 1.75rem;
  }
  header .show ul {
    flex-flow: column nowrap;
  }
  header .show ul li {
    margin-top: 1rem;
  }
  header .burger_menu {
    display: block;
    background-color: #FFFFFF;
    width: 56px;
    height: 56px;
    border: 0;
    cursor: pointer;
  }
  header .burger_menu::before {
    content: url("burger.eb7f342e.svg");
    display: inline-block;
    position: relative;
    top: -12px;
    right: -10px;
    transform: scale(1.4);
  }
}
footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 4rem;
  background-color: #D55860;
  color: #FFFFFF;
}
footer .contact {
  text-align: end;
}
footer a {
  color: #FFFFFF;
}
footer h2 {
  font-weight: bold;
}
footer li {
  min-width: 150px;
}
footer .description {
  max-width: 768px;
  text-align: justify;
  margin: 0 120px;
}
@media screen and (max-width: 1400px) {
  footer {
    display: block;
    padding: 2rem;
  }
  footer .description {
    margin: 56px auto;
  }
}
.home .core {
  display: flex;
  flex-flow: row nowrap;
  max-width: 1600px;
  margin: 8rem auto;
}
.home .core img {
  max-width: 720px;
  aspect-ratio: 1;
  position: relative;
  animation: image-fade-in-bottom 1s ease 0.3s 1 normal backwards;
}
.home .core div {
  max-width: 500px;
  margin: 0 auto;
}
.home .core div section {
  margin-bottom: 3rem;
}
.home .core div section h2 {
  margin-bottom: 2rem;
}
.home .core div section p {
  text-align: justify;
}
.home .core div .catchphrase {
  font-family: Barcelony;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2.5rem;
}
.home .core div .left {
  position: relative;
  left: -50px;
}
.home .core div .right {
  position: relative;
  left: 50px;
}
.home .carousel {
  font-weight: bold;
  font-size: 1.5rem;
  background-color: #FBF7F1;
  padding: 3rem 0;
}
.home .carousel p {
  text-align: center;
  margin: 1.5rem 0;
}
.home .carousel p::before {
  content: url("quotes.7100162f.svg");
  display: inline-block;
  position: relative;
  right: 8px;
  bottom: 8px;
}
.home .carousel p::after {
  content: url("quotes.7100162f.svg");
  display: inline-block;
  position: relative;
  left: 16px;
  top: 4px;
  transform: rotate(180deg);
}
.home .carousel #quotes {
  fill: #D55860;
}
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.slick-dots button {
  border: none;
  width: 0.75rem;
  height: 0.75rem;
  color: transparent;
  background-color: transparent;
}
.slick-dots li {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  background-color: #eb8990;
  margin: 0 0.25rem;
  cursor: pointer;
}
.slick-dots li.slick-active {
  background-color: #D55860;
}
@media screen and (max-width: 1400px) {
  .home .core {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
  }
  .home .core img {
    max-width: 600px;
    margin: 2rem auto;
    position: static;
    animation: none;
  }
  .home .core div {
    max-width: 600px;
    margin: 2rem auto 4rem;
  }
}
@media screen and (max-width: 560px) {
  .home .core img {
    max-width: 100vw;
    margin: 1rem auto;
  }
  .home .core div {
    margin: 1.5rem;
  }
  .home .core div .catchphrase {
    font-size: 1.75rem;
  }
}
.about {
  line-height: 1.75rem;
}
.about .core {
  max-width: 1200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 0 auto;
}
.about .core .cursive {
  font-family: Barcelony;
  font-size: 2.5rem;
}
.about .core a {
  color: #313131;
}
.about .core .col1 {
  margin: 2rem 4rem;
  max-width: 400px;
}
.about .core .col1 .description {
  text-align: justify;
  margin: 1rem 0;
  line-height: 1.75rem;
}
.about .core .col1 .contact {
  float: right;
}
.about .core .col1 .contact .cursive {
  font-size: 2rem;
  color: #D55860;
  margin-top: 1.5rem;
  position: relative;
  left: -2.5rem;
}
.about .core .col1 .contact .details {
  font-weight: bold;
}
.about .core .col1 .contact .details p {
  line-height: 1rem;
}
.about .core .col2 {
  margin: 0 1.75rem;
}
.about .core .col2 .p1 {
  padding: 2rem 4rem;
  margin-bottom: 3rem;
}
.about .core .col2 .p1 h2 {
  margin-bottom: 1.5rem;
}
.about .core .col2 .p1 p {
  text-align: justify;
  line-height: 1.75rem;
}
.about .core .col2 .p2 {
  background-color: #FBF7F1;
  padding: 0 4rem 2rem;
}
.about .core .col2 .p2 h2 {
  font-weight: normal;
  line-height: 2rem;
  position: relative;
  left: -4rem;
  top: -1rem;
}
.about .core .col2 .p2 p {
  text-align: justify;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}
.about .core .col2 .p3 {
  padding: 2rem 4rem;
  margin-top: 2rem;
}
.about .core .col2 .p3 h2 {
  margin-bottom: 1.5rem;
}
.about .core .col2 .p3 p {
  text-align: justify;
}
.about .core .col2 .quote {
  margin: 2rem 0 4rem;
}
.about .core .col2 .quote .Rumi {
  font-size: 1.875rem;
  text-align: right;
  margin-top: 1rem;
}
@media screen and (max-width: 1400px) {
  .about .core {
    max-width: 1200px;
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: center;
    margin: 0 auto;
  }
  .about .core .col1 {
    max-width: 600px;
    margin: 2rem auto;
  }
  .about .core .col1 img {
    width: 600px;
  }
  .about .core .col1 .contact {
    margin-bottom: 2rem;
  }
  .about .core .col2 .quote {
    max-width: 600px;
    margin: 2rem auto;
  }
}
@media screen and (max-width: 560px) {
  .about .core .cursive {
    font-size: 1.75rem;
  }
  .about .core .col1 {
    margin: 0;
  }
  .about .core .col1 img {
    width: 100vw;
  }
  .about .core .col1 .description {
    margin: 2rem;
  }
  .about .core .col1 .contact {
    float: left;
    margin: 1rem 2rem 3rem;
  }
  .about .core .col1 .contact .cursive {
    font-size: 2rem;
    margin-top: 0rem;
    position: relative;
    left: 0;
  }
  .about .core .col1 .contact .details {
    text-align: right;
    position: relative;
    right: -10%;
    margin-top: 1rem;
    font-weight: bold;
  }
  .about .core .col1 .contact .details p {
    line-height: 1.5rem;
  }
  .about .core .col2 {
    margin: 2rem;
  }
  .about .core .col2 .p1 {
    padding: 0;
    margin-bottom: 4rem;
  }
  .about .core .col2 .p2 {
    padding: 0 1.5rem 1rem;
  }
  .about .core .col2 .p2 h2 {
    left: -3vw;
  }
  .about .core .col2 .p2 p {
    margin-bottom: 1rem;
  }
  .about .core .col2 .p3 {
    padding: 0;
    margin: 0;
    margin-top: 4rem;
  }
  .about .core .col2 .quote .Rumi {
    margin: 0;
  }
}
.gestalt section:nth-child(1) {
  max-width: 1400px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin: 4rem auto;
}
.gestalt section:nth-child(1) .left {
  max-width: 600px;
  margin: 2rem;
}
.gestalt section:nth-child(1) .left p {
  margin-top: 1.5rem;
  text-align: justify;
}
.gestalt section:nth-child(1) .right {
  max-width: 600px;
  margin: 2rem;
}
.gestalt section:nth-child(1) .right p:not(:first-child) {
  margin-top: 1.5rem;
}
.gestalt section:nth-child(1) .right .cursive {
  font-family: Barcelony;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  left: -2rem;
  line-height: 2.5rem;
}
.gestalt section:nth-child(1) .right img {
  position: relative;
  animation: image-fade-in-right 1s ease 0.3s 1 normal backwards;
}
.gestalt section:nth-child(2) {
  background-color: #FBF7F1;
}
.gestalt section:nth-child(2) div {
  max-width: 1170px;
  margin: 0 auto;
  padding: 4rem;
}
.gestalt section:nth-child(2) div p {
  margin-top: 2rem;
  text-align: justify;
}
.gestalt section:nth-child(3) {
  max-width: 1300px;
  display: flex;
  flex-flow: row nowrap;
  margin: 6rem auto;
}
.gestalt section:nth-child(3) .framework {
  width: 44%;
  margin: 0 4rem;
}
.gestalt section:nth-child(3) .framework p {
  margin-top: 2rem;
  text-align: justify;
}
.gestalt section:nth-child(3) .cta {
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.gestalt section:nth-child(3) .cta .catchphrase {
  font-family: Barcelony;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.gestalt section:nth-child(3) .cta .left {
  position: relative;
  left: -50px;
}
.gestalt section:nth-child(3) .cta .right {
  position: relative;
  left: 50px;
}
@media screen and (max-width: 1400px) {
  .gestalt section:nth-child(1) .right img {
    width: 50vw;
    position: static;
    animation: none;
  }
  .gestalt section:nth-child(1) .right .cursive {
    left: 0;
  }
}
@media screen and (max-width: 800px) {
  .gestalt section:nth-child(1) {
    display: block;
  }
  .gestalt section:nth-child(1) .left, .gestalt section:nth-child(1) .right {
    max-width: 100%;
    margin: 4rem;
  }
  .gestalt section:nth-child(1) .left img, .gestalt section:nth-child(1) .right img {
    width: 100vw;
    position: relative;
    left: -4rem;
  }
  .gestalt section:nth-child(3) {
    display: block;
    margin: 2rem;
  }
  .gestalt section:nth-child(3) .framework {
    width: auto;
    margin: 2rem;
  }
  .gestalt section:nth-child(3) .cta {
    margin: 4rem 2rem;
  }
}
@media screen and (max-width: 560px) {
  .gestalt section:nth-child(1) .left, .gestalt section:nth-child(1) .right {
    margin: 2rem;
  }
  .gestalt section:nth-child(1) .left .cursive, .gestalt section:nth-child(1) .right .cursive {
    font-size: 1.5rem;
  }
  .gestalt section:nth-child(1) .left img, .gestalt section:nth-child(1) .right img {
    position: relative;
    left: -2rem;
  }
  .gestalt section:nth-child(2) {
    background-color: #FBF7F1;
  }
  .gestalt section:nth-child(2) div {
    padding: 2rem;
  }
  .gestalt section:nth-child(3) {
    display: block;
    margin: 1rem;
  }
  .gestalt section:nth-child(3) .framework {
    width: auto;
    margin: 2rem 1rem;
  }
  .gestalt section:nth-child(3) .cta {
    margin: 4rem 2rem;
  }
  .gestalt section:nth-child(3) .cta .catchphrase {
    font-size: 1.75rem;
  }
}
.sand .resp section:nth-child(1) {
  max-width: 1680px;
  margin: 80px auto;
  display: flex;
}
.sand .resp section:nth-child(1) img {
  display: block;
  max-width: 800px;
  max-height: 544px;
  width: auto;
  height: auto;
  margin: auto 0;
  position: relative;
  animation: image-fade-in-left 1s ease 0.3s 1 normal backwards;
}
.sand .resp section:nth-child(1) div {
  max-width: 500px;
  margin: 0 auto;
}
.sand .resp section:nth-child(1) div p {
  text-align: justify;
  margin-top: 1.5rem;
}
.sand .resp section:nth-child(2) {
  background-color: #FBF7F1;
}
.sand .resp section:nth-child(2) .container {
  max-width: 1680px;
  margin: 0 auto;
  display: flex;
}
.sand .resp section:nth-child(2) .container div {
  padding: 4rem 10rem;
}
.sand .resp section:nth-child(2) .container img {
  max-height: 600px;
  position: relative;
  animation: image-fade-in-right 1s ease 0.6s 1 normal backwards;
}
.sand .resp section:nth-child(2) .container p, .sand .resp section:nth-child(2) .container h2 {
  margin-top: 2rem;
  text-align: justify;
}
.sand .resp section:nth-child(3) {
  max-width: 900px;
  margin: 8rem auto;
}
.sand .resp section:nth-child(3)::before {
  content: url("quotes.7100162f.svg");
  display: inline-block;
  position: relative;
  top: 24px;
}
.sand .resp section:nth-child(3) > p {
  margin: 0 2rem 2rem 2rem;
}
.sand .resp section:nth-child(3) div {
  position: relative;
  right: 160px;
}
.sand .resp section:nth-child(3) .name {
  font-family: Barcelony;
  font-size: 2.5rem;
  text-align: end;
}
.sand .resp section:nth-child(3) .ref {
  margin-top: 0.4rem;
  text-align: end;
}
@media screen and (max-width: 1600px) {
  .sand .resp section:nth-child(1) img {
    width: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .sand .resp section:nth-child(1) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .sand .resp section:nth-child(1) img {
    max-width: 768px;
    width: 100%;
    position: static;
    animation: none;
  }
  .sand .resp section:nth-child(1) div {
    max-width: 768px;
    padding: 2rem;
  }
  .sand .resp section:nth-child(2) .container {
    justify-content: center;
    align-items: center;
  }
  .sand .resp section:nth-child(2) .container h2 {
    margin-top: 0;
  }
  .sand .resp section:nth-child(2) .container div {
    padding: 4rem;
  }
  .sand .resp section:nth-child(2) .container img {
    position: static;
    animation: none;
  }
  .sand .resp section:nth-child(3) .name {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 800px) {
  .sand .resp section:nth-child(2) .container {
    display: block;
  }
  .sand .resp section:nth-child(2) .container img {
    display: block;
    width: 50%;
    max-height: 100%;
    margin: 0 auto;
  }
  .sand .resp section:nth-child(3)::before {
    top: 8px;
    left: 8px;
    transform: rotate(20deg);
  }
}
@media screen and (max-width: 560px) {
  .sand .resp section:nth-child(2) .container {
    display: block;
  }
  .sand .resp section:nth-child(2) .container div {
    padding: 2rem;
  }
  .sand .resp section:nth-child(2) .container img {
    display: block;
    width: 100%;
    max-height: 100%;
    margin: 0;
  }
  .sand .resp section:nth-child(3) {
    margin: 4rem auto;
  }
  .sand .resp section:nth-child(3) div {
    right: 4rem;
  }
}
@media screen and (max-width: 400px) {
  .sand .resp section:nth-child(3) .name {
    font-size: 1.5rem;
  }
  .sand .resp section:nth-child(3) .ref {
    font-size: 1rem;
  }
  .sand .resp section:nth-child(3) div {
    right: 2.4rem;
  }
}
.error404 {
  width: 100%;
  position: absolute;
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.error404 p {
  font-size: 1.25rem;
  padding: 2rem;
  margin: 20vh auto;
  text-align: center;
}
/*# sourceMappingURL=404.6e1ec3db.css.map */
