.sand {
    .resp {
        section:nth-child(1) {
            max-width: 1680px;
            margin: 80px auto;
            display: flex;
            img {
                display: block;
                max-width: 800px;
                max-height: 544px;
                width: auto;
                height: auto;
                margin: auto 0;
                position: relative;
                animation: image-fade-in-left 1s ease .3s 1 normal backwards;
            }
            div {
                max-width: 500px;
                margin: 0 auto;
                p {
                    text-align: justify;
                    margin-top: 1.5rem;
                }
            }
        }
        section:nth-child(2) {
            background-color: $beige;
            .container {
                max-width: 1680px;
                margin: 0 auto;
                display: flex;

                div {
                    padding: 4rem 10rem;
                }

                img {
                    max-height: 600px;
                    position: relative;
                    animation: image-fade-in-right 1s ease .6s 1 normal backwards;
                }

                p, h2 {
                    margin-top: 2rem;
                    text-align: justify;
                }
            }
        }
        section:nth-child(3) {
            max-width: 900px;
            margin: 8rem auto;

            &::before {
                content: url("../assets/icons/quotes.svg");
                display: inline-block;
                position: relative;
                top: 24px;
            }

            & > p {
                margin: 0 2rem 2rem 2rem;
            }

            div {
                position: relative;
                right: 160px;
            }

            .name {
                font-family: Barcelony;
                font-size: 2.5rem;
                text-align: end;
            }

            .ref {
                margin-top: .4rem;
                text-align: end;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .sand {
        .resp {
            section:nth-child(1) {
                img {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .sand {
        .resp {
            section:nth-child(1) {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                img {
                    max-width: 768px;
                    width: 100%;
                    position: static;
                    animation: none;
                }
                div {
                    max-width: 768px;
                    padding: 2rem;
                }
            }
            section:nth-child(2) {
                .container {
                    justify-content: center;
                    align-items: center;
                    h2 {
                        margin-top: 0;
                    }
                    div {
                        padding: 4rem;
                    }
                    img {
                        position: static;
                        animation: none;
                    }
                }
            }
            section:nth-child(3) {
                .name {
                    font-size: 1.75rem;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .sand {
        .resp {
            section:nth-child(2) {
                .container {
                    display: block;

                    img {
                        display: block;
                        width: 50%;
                        max-height: 100%;
                        margin: 0 auto;
                    }
                }
            }
            section:nth-child(3) {
                &::before {
                    top: 8px;
                    left: 8px;
                    transform: rotate(20deg);
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .sand {
        .resp {
            section:nth-child(2) {
                .container {
                    display: block;

                    div {
                        padding: 2rem;
                    }

                    img {
                        display: block;
                        width: 100%;
                        max-height: 100%;
                        margin: 0;
                    }
                }
            }
            section:nth-child(3) {
                margin: 4rem auto;

                div {
                    right: 4rem;
                }
            }
        }
    }
}



@media screen and (max-width: 400px) {
    .sand {
        .resp {
            section:nth-child(3) {
                .name {
                    font-size: 1.5rem;
                }
                .ref {
                    font-size: 1rem;
                }
                div {
                    right: 2.4rem;
                }
            }
        }
    }
}